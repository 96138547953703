import Login from '@/views/Login.vue'
import Error404 from '@/views/Error404.vue'
import DashboardHome from '@/views/DashboardHome.vue'
import AppUpdate from '@/views/AppUpdate.vue'
import crud from './crud'
import breadcrumbs from './breadcrumbs'
// import ContentUpdate from '@/views/ContentUpdate.vue'

export default [
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'login'
        }
    },
    {
        path: '/',
        component: DashboardHome,
        meta: {
            breadcrumbs: [
                {
                    text: 'Главная',
                    disabled: true
                }
            ]
        }
    },
    {
        path: '/app-update',
        component: AppUpdate,
        meta: {
            section: 'app_update',
            breadcrumbs: [
                {
                    text: 'Главная',
                    disabled: false,
                    to: `/`
                },
                {
                    text: 'Обновления приложения',
                    disabled: true,
                    to: `/app-update`
                }
            ]
        }
    },
    // {
    //     path: '/content-update',
    //     name: 'ContentUpdate',
    //     component: ContentUpdate,
    //     meta: {
    //         breadcrumbs: [
    //             {
    //                 text: 'Главная',
    //                 disabled: false
    //             },
    //             {
    //                 text: 'Обновления контента',
    //                 disabled: true,
    //                 to: `/content-update`
    //             }
    //         ]
    //     }
    // },
    ..._.flatten(_.map(crud, (item) => {
        const module = item.module.replaceAll('_', '-')
        return [{
                path: `/${module}`,
                component: () => import(`@/views/${module}/Index.vue`),
                meta: {
                    section: module.replaceAll('-', '_'),
                    breadcrumbs: breadcrumbs[module].index
                }
            },
            {
                path: `/${module}/create`,
                component: () => import(`@/views/${module}/Create.vue`),
                meta: {
                    section: module.replaceAll('-', '_'),
                    breadcrumbs: breadcrumbs[module].create
                }
            },
            {
                path: `/${module}/update/:id`,
                component: () => import(`@/views/${module}/Update.vue`),
                meta: {
                    section: module.replaceAll('-', '_'),
                    breadcrumbs: breadcrumbs[module].update
                }
            }
        ];
    })),
    {
        path: `/user/upload`,
        component: () => import(`@/views/user/Upload.vue`),
        meta: {
            section: 'user',
            breadcrumbs: [{
                text: 'Главная',
                disabled: false,
                to: `/`,
            },
            {
                text: 'Пользователи',
                disabled: true,
                to: `/`,
            },
            {
                text: 'Загрузка пользователей',
                disabled: true,
                to: `/user/upload`,
            }]
        }
    },
    {
        path: `/user/info`,
        component: () => import(`@/views/user/Info.vue`),
        meta: {
            section: 'user',
            breadcrumbs: [{
                text: 'Главная',
                disabled: false,
                to: `/`,
            },
            {
                text: 'Пользователи',
                disabled: true,
                to: `#`,
            }]
        }
    },
    {
        path: `/update-admin-password`,
        component: () => import(`@/views/UpdateAdminPassword.vue`),
        meta: {
            section: 'update_admin_password',
            breadcrumbs: [{
                text: 'Главная',
                disabled: false,
                to: `/`,
            },
            {
                text: 'Восстановление доступа',
                disabled: true,
                to: `#`,
            }]
        }
    },
    {
        path: `/performance`,
        component: () => import(`@/views/performance/Index.vue`),
        meta: {
            section: 'performance',
            breadcrumbs: [{
                text: 'Главная',
                disabled: false,
                to: `/`,
            },
            {
                text: 'Успеваемость',
                disabled: true,
                to: `#`,
            }]
        }
    },
    {
        path: '*',
        name: 'Error404',
        component: Error404,
        meta: {
            breadcrumbs: [{
                    text: 'Главная',
                    disabled: false,
                    to: '/'
                },
                {
                    text: 'Страница не найдена',
                    disabled: true
                }
            ]
        }
    }
]