import ApiHelper from '@/helpers/ApiHelper.js'
import localForage from "localforage"
import { USER_SERVER } from '@/servers'

localForage.config({
    driver: localForage.INDEXEDDB,
    name: `${process.env.VUE_APP_CODENAME}_app_storage`
})

export default class {
    constructor() {
        this.state = {
            access_token: null,
            refresh_token: null,
            profile: null
        };
        this.mutations = {
            LOGIN (state, data) {
                state.access_token = data.access_token
                state.refresh_token = data.refresh_token
                state.profile = data.profile

                localForage.setItem('access_token', state.access_token)
                localForage.setItem('refresh_token', state.refresh_token)
                localForage.setItem('profile', state.profile)
            },
            LOGOUT (state) {
                state.access_token = null
                state.refresh_token = null
                state.profile = null

                localForage.removeItem('profile')
                localForage.removeItem('access_token')
                localForage.removeItem('refresh_token')
            }
        };
        this.actions = {
            setSession ({commit}, data) {

                if (!data.profile) {
                    const profile = {}
                    Object.keys(data).forEach(key => {
                        if (['access_token', 'refresh_token'].includes(key)) { return }
                        profile[key] = data[key]
                    })
                    data.profile = profile
                }
                commit('LOGIN', {
                    access_token: _.get(data, 'access_token', null),
                    refresh_token: _.get(data, 'refresh_token', null),
                    profile: data.profile
                })
            },
            // Try to login using access_token saved early.
            // If token expired, trying to refresh it
            // If refreshed successfuly, trying to login again
            async fetchProfile ({ dispatch }, accessToken) {
                const fields = ['access_token', 'refresh_token', 'login', 'role', 'name', 'tree']
                const response = await ApiHelper.get(`${USER_SERVER}/api/user/profile`, { fields: fields.join(',') }, { token: accessToken })

                if (!response?.success) { throw new Error(response.error) }
                    
                // Save session data
                dispatch('setSession', response.data)
            },
            // Refresh tokens by refreshToken
            // If process fails, user will transfer to login page
            async refreshTokens ({ dispatch, state }, refreshToken) {
                if (!refreshToken) {
                    refreshToken = await localForage.getItem('refresh_token')
                }
                const response = await ApiHelper.get(`${USER_SERVER}/api/user/refresh-tokens?token=${refreshToken}`)
                
                if (!response) { return null }
                // Save session data
                await dispatch('setSession', {
                    access_token: response.data.access_token,
                    refresh_token: response.data.refresh_token,
                    profile: state.profile
                });
                return response
            },
            // Works like fetchProfile, but use access_token from localForage
            async restoreSession ({ dispatch }) {
                try {

                    const access_token = await localForage.getItem('access_token')

                    if (!access_token) { return false }

                    await dispatch('fetchProfile', access_token)

                    return true
                } catch (e) {
                    console.error(e)
                    return false
                }
            },
            async login (context, data) {
                const fields = ['access_token', 'refresh_token', 'login', 'role', 'name']
                return ApiHelper.post(`${USER_SERVER}/api/user/login?fields=${fields.join(',')}`, data)
            },
            async logout ({ commit }) {
                commit('LOGOUT')
                return true
            },
            async register (context, data) {
                return ApiHelper.post(`${USER_SERVER}/api/user/register`, data)
            },
            async changePassword (context, data) {
                return ApiHelper.post(`${USER_SERVER}/api/user/change-password`, data)
            }
        };
        this.getters = {
            isAuthorized: (state) => {
                return !_.isNil(_.get(state, 'profile.id', null))
            },
            isAdmin: (state) => {
                return state.profile?.role === 'admin'
            },
            rolePriority: (state) => {
                return state.roles.find(role => role.value === state.profile?.role)?.priority
            }
        }
    }
}