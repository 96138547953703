export default [
    {
        module: 'fioko_data_collection',
        name: ['форма сбора данных ФИС ОКО', 'форма сбора данных ФИС ОКО']
    },
    {
        module: 'user',
        name: ['пользователи', 'пользователя']
    },
    {
        module: 'group',
        name: ['классы', 'классы']
    },
    {
        module: 'license',
        name: ['лицензии', 'лицензии']
    }
]