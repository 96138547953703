import Crud from '@/store/classes/crud'
import { JOB_SERVER, JOB_SERVER_API_KEY } from '@/servers'

const crud = new Crud({ module: 'theme_of_task', host: JOB_SERVER, api_key: JOB_SERVER_API_KEY })

export default {
    namespaced: true,
    state: {
        ...crud.state
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}