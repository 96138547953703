export default {
    computed: {
        isCanViewUserSection () {
            return this.$store.getters['user/userCan']('user', 'view')
        },
        isCanViewGroupSection () {
            return this.$store.getters['user/userCan']('group', 'view')
        },
        isCanViewLicenseSection () {
            return this.$store.getters['user/userCan']('license', 'view')
        },
        isCanEditFiokoDataCollectionSection () {
            return this.$store.getters['user/userCan']('fioko_data_collection', 'edit')
        },
        isCanEditUpdateAdminPasswordSection () {
            return this.$store.getters['user/userCan']('update_admin_password', 'edit')
        }
    }
}