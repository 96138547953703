import _ from 'lodash'
import ApiHelper from '@/helpers/ApiHelper.js'
import { USER_SERVER, USER_SERVER_API_KEY } from '@/servers'

export default class {
    constructor(options) {
        const API_MODULE = options.module.replaceAll('_', '-');
        const API_HOST = options.host || USER_SERVER
        const API_KEY = options.api_key || USER_SERVER_API_KEY
        
        this.state = {
            filter: {},
            grid: {
                index: {
                    options: {
                        page: 1,
                        itemsPerPage: 20
                    }
                }
            }
        };
        this.mutations = {
            SET_FILTER(state, data) {
                state.filter = data;
            },
            RESET_FILTER(state) {
                state.filter = _.mapValues(state.filter, () => null)
            },
            UPDATE_GRID_OPTIONS(state, data) {
                _.assign(state.grid, data);
            }
        };
        this.actions = {
            filter({commit}, data) {
                commit('SET_FILTER', data);
                return true;
            },
            reset({commit}) {
                commit('RESET_FILTER');
                return true;
            },
            async one(context, data) {
                return ApiHelper.get(`${API_HOST}/api/${API_MODULE}/one`, data, { api_key: API_KEY });
            },
            async list(context, data) {
                return ApiHelper.get(`${API_HOST}/api/${API_MODULE}`, data, { api_key: API_KEY });
            },
            async create(context, data) {
                return await ApiHelper.post(`${API_HOST}/api/${API_MODULE}/create`, data, {}, { api_key: API_KEY });
            },
            async update(context, data) {
                return await ApiHelper.put(`${API_HOST}/api/${API_MODULE}/update`, data, { id: _.get(data, 'id', 0) }, { api_key: API_KEY });
            },
            async delete(context, data) {
                return await ApiHelper.delete(`${API_HOST}/api/${API_MODULE}/delete`, data, {
                    id: _.get(data, 'id', 0),
                    api_key: API_KEY
                });
            },
            updateGridOptions({commit}, data) {
                commit('UPDATE_GRID_OPTIONS', data);
                return true;
            }
        }
    }
}