<template>
    <v-row align="center" justify="center" dark>
        <v-col cols="12" sm="8" md="6">
            <v-tabs background-color="purple darken-2" dark>
                <!-- Tabs -->
                <v-tab>Войти</v-tab>
                <v-tab>Создать аккаунт</v-tab>
                <v-spacer />
                <!-- Tab items -->
                <v-tab-item>
                    <login-form 
                        @success="redirectOnHome" 
                        @back="redirectOnHome" 
                    />
                </v-tab-item>
                <v-tab-item>
                    <register-form 
                        new-account 
                        @success="redirectOnHome" 
                    />
                </v-tab-item>
            </v-tabs>
        </v-col>
    </v-row>
</template>
<script>
import LoginForm from '@/components/auth/LoginForm.vue'
import RegisterForm from '@/components/auth/RegisterForm.vue'
export default {
    name: 'Login',
    components: { LoginForm, RegisterForm },
    created () {
        this.$store.getters['user/isAuthorized'] && this.redirectOnHome()
    },
    methods: {
        redirectOnHome() {
            window.location.href = '/';
            return true;
        }
    }
}
</script>