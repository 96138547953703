<template>
    <div>
        <p>Приветствуем Вас в Административной части системы ОКО ВПР!</p>
        <p v-if="isCanViewGroupSection && isCanViewUserSection">Здесь Вы можете:</p>
        <ul>
            <li v-if="isCanViewGroupSection">создавать классы и наполнять их учащимися;</li>
            <li v-if="isCanViewUserSection">создавать учителей и устанавливать классы, которым учитель сможет назначать работы.</li>
        </ul>
        <p>&nbsp;</p>
        <p v-if="isCanViewUserSection">В разделе <router-link to="/user/info">«Пользователи»</router-link> можно создавать пользователей. Более подробно об этом можно прочитать в самом <router-link to="/user/info">разделе</router-link>.</p>
        <p v-if="isCanViewGroupSection">В разделе <router-link to="/group">«Классы»</router-link> можно создавать новые классы и наполнять их учениками и педагогами.</p>
        <p v-if="isCanViewLicenseSection">В разделе <router-link to="/license">«Лицензии»</router-link> можно просматривать текущие лицензии и добавлять новые лицензии.</p>
    </div>
</template>

<script>
import isUserCanMixin from '@/mixins/isUserCanMixin'

export default {
    mixins: [ isUserCanMixin ],
    
    computed: {
        userRole () {
            return this.$store.state.user.profile.role
        }
    }
}
</script>