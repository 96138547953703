import _ from 'lodash'
import crud from './crud'

let breadcrumbs = {}

// Crud items
_.map(crud, (item) => {
    
    const module = item.module.replaceAll('_', '-')
    
    _.set(breadcrumbs, `${module}.index`, [{
            text: 'Главная',
            disabled: false,
            to: '/'
        },
        {
            text: _.upperFirst(_.get(item, 'name.0', 'элементы')),
            disabled: true,
            to: `/${module}`,
        }
    ]);
    _.set(breadcrumbs, `${module}.create`, [
        ...breadcrumbs[module].index.map((o, i) => ({...o, disabled: module === 'user' && i === 1})),
        ...[{
            text: `Создание ${_.get(item, 'name.1', 'элемента')}`,
            disabled: true,
            to: `/${module}/create`,
        }]
    ])
    _.set(breadcrumbs, `${module}.update`, [
        ...breadcrumbs[module].index.map((o, i) => ({...o, disabled: module === 'user' && i === 1 })),
        ...[{
            text: `Редактирование ${_.get(item, 'name.1', 'элемента')}`,
            disabled: true,
            to: `/${module}/update/:id`,
        }]
    ])
})

export default breadcrumbs