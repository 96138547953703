import store from '@/store/index.js'
import router from '@/router'
import axios from 'axios'
import { USER_SERVER_API_KEY } from '@/servers'

const GET    = 'get',
      POST   = 'post',
      PUT    = 'put',
      PATCH  = 'patch',
      DELETE = 'delete';

export default {
    async request(url, method = 'get', data = {}, params = {}, options = {}) {

        // options are: 'arraybuffer', 'document', 'json', 'text', 'blob'
        if (!options.responseType) { options.responseType = 'json' }

        let token = _.get(options, 'token', _.get(store.state.user, 'access_token', ''))
        const api_key = _.get(options, 'api_key', USER_SERVER_API_KEY)
        const makeRequest = () => axios({
            url: url,
            method: method,
            params: params,
            data: data,
            responseType: options.responseType,
            headers: {
                'X-API-Key': api_key,
                'Authorization': _.isNil(token) ? '' : ('Bearer ' + token),
            }
        })
        const abortAndLogout = async () => {
            await store.dispatch('user/logout');
            router.push('/').catch(() => {});
        }

        try {
            const { data, http_code } = await makeRequest()
            if (http_code === 401) { return abortAndLogout() }
            return data
        } catch (error) {

            if (!error.response) {
                console.error(error)
                store.dispatch('app/updateIsOnlineValue', false)
                return false
            }

            if (error.response.data.http_code === 401) { return abortAndLogout() }

            if (error.response.data.http_code !== 419) { return error.response.data }

            const response = await store.dispatch('user/refreshTokens')

            if (!response || response.http_code === 401) { return abortAndLogout() }
            
            try {
                token = response.data.access_token
                const { data } = await makeRequest()
                return data
            } catch (error) {
                return abortAndLogout()
            }
        }
    },
    async [GET](url, params, options = {}) {
        return await this.request(url, GET, {}, params, options);
    },
    async [POST](url, data, params = {}, options = {}) {
        return await this.request(url, POST, data, params, options);
    },
    async [PUT](url, data, params = {}, options = {}) {
        return await this.request(url, PUT, data, params, options);
    },
    async [PATCH](url, data, params = {}, options = {}) {
        return await this.request(url, PATCH, data, params, options);
    },
    async [DELETE](url, params, options = {}) {
        return await this.request(url, DELETE, {}, params, options);
    }
}