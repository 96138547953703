<template>
    <div>
        <p><strong>Текущая версия приложения:</strong> <v-chip>{{ version }}</v-chip></p>
        <v-divider class="my-3" />
        <p>
            <v-btn 
                color="purple darken-2" 
                class="ml-auto white--text"
                :loading="loading"
                @click.prevent.stop="check"
            >
                Проверить наличие обновлений
            </v-btn>
        </p>

        <div v-if="checked">
            <p v-if="!appUpdatesAvailable">
                Вы используете самую последнюю версию приложения.
            </p>
            <p v-else>
                <v-alert
                    color="purple darken-2"
                    outlined
                    prominent
                    icon="mdi-alert-decagram"
                >
                    <div class="w-100 d-flex flex-row flex-nowrap justify-space-between align-center">
                        <h3 class="fs-16 lh-22 fw-500">Доступна новая версия {{ _.get(appUpdates, 'version', '') }}!</h3>
                        <v-btn 
                            :elevation="0" 
                            dark
                            color="purple darken-2"
                            @click.prevent.stop="download"
                        >
                            Скачать
                        </v-btn>
                    </div>
                </v-alert>
            </p>
        </div>
    </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
const currentVersion = require('@/../package.json').version;
export default {
    data() {
        return {
            version: currentVersion,
            $updater: null,
            loading: false,
            checked: false
        }
    },
    computed: {
        ...mapState('app', ['appUpdates']),
        ...mapGetters('app', ['appUpdatesAvailable'])
    },
    methods: {
        async check() {
            this.loading = true;
            await this.$store.dispatch('app/checkAppUpdates');
            this.loading = false;
            this.checked = true;
        },
        download() {
            window.location.assign(this.appUpdates.url);
        }
    }
}
</script>
<style lang="scss" scoped></style>
