import Crud from '@/store/classes/crud'
import { WORKFLOW_SERVER, WORKFLOW_SERVER_API_KEY} from '@/servers'

const crud = new Crud({ module: 'result_of_task', host: WORKFLOW_SERVER, api_key: WORKFLOW_SERVER_API_KEY })

export default {
    namespaced: true,
    state: {
        ...crud.state
    },
    mutations: {
        ...crud.mutations
    },
    actions: {
        ...crud.actions
    }
}