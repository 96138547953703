

export default {
    namespaced: true,
    state: {
        filter: null
    },
    mutations: {
        setFilter (state, value) {
            if (!('started_at' in value) || !('subject' in value))
                throw new Error('Given object is not "filter" like')

            state.filter = value
        }
    },
    actions: {
        setFilter ({ commit }, value) {
            commit('setFilter', value)
        }
    }
}