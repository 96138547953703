// User server
const USER_SERVER = process.env.VUE_APP_USER_SERVER_HOST?.replace('{hostname}', window.location.hostname);
const USER_SERVER_API_KEY = process.env.VUE_APP_USER_SERVER_API_KEY;
// Code server
const CODE_SERVER = process.env.VUE_APP_CODE_SERVER_HOST?.replace('{hostname}', window.location.hostname);
const CODE_SERVER_API_KEY = process.env.VUE_APP_CODE_SERVER_API_KEY;
// JOB server
const JOB_SERVER = process.env.VUE_APP_JOB_SERVER_HOST?.replace('{hostname}', window.location.hostname);
const JOB_SERVER_API_KEY = process.env.VUE_APP_JOB_SERVER_API_KEY;
// JOB external server (for getting updates)
const JOB_EXTERNAL_SERVER = process.env.VUE_APP_JOB_EXTERNAL_SERVER_HOST?.replace('{hostname}', window.location.hostname);
const JOB_EXTERNAL_SERVER_API_KEY = process.env.VUE_APP_JOB_EXTERNAL_SERVER_API_KEY

// Workflow server
const WORKFLOW_SERVER = process.env.VUE_APP_WORKFLOW_SERVER_HOST?.replace('{hostname}', window.location.hostname);
const WORKFLOW_SERVER_API_KEY = process.env.VUE_APP_WORKFLOW_SERVER_API_KEY;

export { 
	USER_SERVER, USER_SERVER_API_KEY,
	CODE_SERVER, CODE_SERVER_API_KEY,
	JOB_SERVER, JOB_SERVER_API_KEY,
    JOB_EXTERNAL_SERVER, JOB_EXTERNAL_SERVER_API_KEY,
	WORKFLOW_SERVER, WORKFLOW_SERVER_API_KEY
}