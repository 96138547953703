import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import license from './modules/license'
import user from './modules/user'
import group from './modules/group'
import fioko_data_collection from './modules/fioko_data_collection'
import fioko_report from './modules/fioko_report'
import assigned_job from './modules/assigned_job'
import result from './modules/result'
import result_of_task from './modules/result_of_task'
import theme_of_task from './modules/theme_of_task'
import performance from './modules/performance'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        license,
        user,
        group,
        fioko_data_collection,
        fioko_report,
        assigned_job,
        result,
        result_of_task,
        theme_of_task,
        performance
    }
})